import React, { useEffect, useMemo, useState } from 'react';
import { Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getOrderPotentialData, getPlantations, getPropertyNames } from '../../../api/AllAddress ';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHTBody, BOHTD, BOHTDPressable, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';
import { BOHButton, BOHTlbCheckbox, BOHTlbrSearchPicker, BOHToolbar, renderBOHTlbDatePicker } from '../../../common/components/bohtoolbar';
import { TextdefaultSize } from '../../../common/constants/Fonts';
import { formatDate } from '../../../common/utils/DateUtils';
import { API_URL } from '../../../common/constants/AppConstants';
import { getPmcList } from '../../../api/Settings';
import { useSorting } from '../../../common/hooks/useSorting';

const OrderPotential = ({ navigation }) => {
  const { showAlert } = useAlertModal();

  const [tableData, setTableData] = useState([]);
  // const [totalNights, setTotalNights] = useState(0);
  const [updateLocationTrigger, setUpdateLocationsTrigger] = useState(false);
  const InitialWidths = [200, 90, 90, 80, 160, 160, 100, 80];
  const [searchKey, setSearchKey] = useState('');
  const [daysArray, setdaysArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    key: null,
    direction: null,
  })

  const [plantataions, setPlantations] = useState([]);
  const [propertyNames, setPropertyNames] = useState([]);
  const [pmcs, setPmcsData] = useState([]);

  const today = new Date();
  const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [searchOptions, setSearchOptions] = useState({
    start_date: formatDate(firstDateOfMonth),
    end_date: formatDate(lastDateOfMonth),
    xploriefif: false,
    xplorievoucher: false,
    plantation: '',
    property_name: '',
    pmc: '',
  });

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    getPlantations((jsonRes) => {
      setPlantations(jsonRes.filter(item => !item.includes('Beach & Tennis')));
    });
    getPropertyNames((jsonRes) => {
      setPropertyNames(jsonRes);
    });
    getPmcList((jsonRes) => {
      const filteredData = jsonRes.filter(item => item.pmc && item.pmc.trim() !== '');
      setPmcsData(filteredData);
    });
  }, [])

  useEffect(() => {
    setLoading(true);
    setUpdateLocationsTrigger(true);
  }, [searchOptions])

  useEffect(() => {
    if (updateLocationTrigger == true) getTable();
  }, [updateLocationTrigger]);

  useEffect(() => {
    setUpdateLocationsTrigger(true);
  }, [searchKey])

  const getTable = () => {
    setLoading(true);
    getOrderPotentialData({ searchOptions }, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateLocationsTrigger(false);
          setTableData(jsonRes.gridData);
          // setTotalNights(jsonRes.totalNights);
          setdaysArray(jsonRes.daysArray);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
      setLoading(false);
    });
  };

  // const getCellColor = (percentage) =>{
  //   let bgcolor = "gray";

  //   // if(percentage>1) bgcolor = "green";
  //   // else 
  //   if(percentage == null) bgcolor = "gray";
  //   else if(percentage == 0) bgcolor = "red";
  //   else if(percentage<0.75) bgcolor = "green";
  //   else if(percentage>0.75) bgcolor = "yellow";

  //   return bgcolor;
  // }
  const getCellColor = (percentage, isRented) => {
    if (percentage >= 0.75) return "blue";
    else if (percentage > 0 && percentage < 0.75) return "red";
    else if (isRented ) return "blue";
    return "transparent";
  }
  // const getCellTextColor = (percentage, price) =>{
  //   let textColor = "black";

  //   if(percentage == null) textColor = "black";
  //   else if(percentage == 0){
  //     if(price && price>0) textColor = "white";
  //     else textColor = "#999999";
  //   }
  //   else if(percentage<0.75) textColor = "black";
  //   else if(percentage>0.75) textColor = "black";

  //   return textColor;
  // }
  const getCellTextColor = (percentage, price, isRented) => {
    if (percentage >= 0.75) return "white";
    else if (percentage > 0 && percentage < 0.75) return "white";
    // else if (isRented ) return "blue";
    return "black";
  }

  const formatCellContent = (subItem) => {
    if (subItem === null) return " ";
    if (subItem.price == 0) return " ";
    if (subItem.percentage >= 0.75 || subItem.percentage < 0.75) {
      return `$${subItem.price.toFixed(2)}`;
    }
    if (subItem.isRented) return " ";
    return " ";
  }
  const exportForecasting = () => {
    location.href = API_URL + `/forecasting/exportforecasting?start_date=${searchOptions.start_date}&end_date=${searchOptions.end_date}`
  }

  const openReservations = (searchOptions) => {

    AsyncStorage.setItem('__potential_options', JSON.stringify(searchOptions))

    if (Platform.OS == 'web') {
      window.open('/home/PotentialList');
    }
  }

  // const renderTableData = () => {
  //   const rows = [];
  //   if (tableData && tableData.length && tableData.length > 0) {
  //     tableData.map((item, index) => {
  //       rows.push(
  //         <BOHTR key={index}>
  //           <BOHTD width={InitialWidths[0]}>{item.plantation||" "}</BOHTD>
  //           <BOHTD width={InitialWidths[1]}>{item.xploriefif?'FIF':item.xplorievoucher?'Voucher':''}</BOHTD>
  //           <BOHTD width={InitialWidths[2]}>{item.property_type == 0?"House": item.property_type == 1 ? "Condo" : ''}</BOHTD>
  //           <BOHTD width={InitialWidths[3]}>{item.number||" "}</BOHTD>
  //           <BOHTD width={InitialWidths[4]}>{item.street||" "}</BOHTD>
  //           <BOHTD width={InitialWidths[5]}>{item.property_name||" "}</BOHTD>
  //           <BOHTD width={InitialWidths[6]}>{item.potential||" "}</BOHTD>
  //           <BOHTD width={InitialWidths[7]} textAlign={'right'}>{item.guests}</BOHTD>
  //           { item.queryResult.map((subItem, index)=>{
  //             let bgcolor = getCellColor(subItem?.percentage??null);
  //             let txtColor = getCellTextColor(subItem?.percentage??null, subItem?.price);
  //             return (
  //               <BOHTDPressable
  //                 key={index} width={40}
  //                 style={{
  //                   backgroundColor:bgcolor,
  //                   borderRightWidth: 1,
  //                   borderRightColor: '#f2f2f2',
  //                   borderLeftWidth: 1,
  //                   borderLeftColor: '#f2f2f2',
  //                   paddingHorizontal: 0,
  //                   height: '100%',
  //                 }}
  //                 textProps={{
  //                   style:{
  //                     fontWeight:'bold',
  //                     textAlign:'center',
  //                     color: txtColor,
  //                 }}}
  //                 onPress={()=>{
  //                   if(subItem){
  //                     openReservations({
  //                       date: subItem.date,
  //                       address: `${item.number} ${item.street} ${item.property_name} ${item.plantation}`,
  //                       ids: subItem.ids,
  //                       potential: subItem?.price??0,
  //                     })}
  //                   }
  //                 }>
  //                 {subItem?.price??" "}
  //               </BOHTDPressable>
  //             );
  //           })}
  //         </BOHTR>
  //       );
  //     });
  //   } else {
  //     <></>;
  //   }
  //   return <>{rows}</>;
  // };
  // const { sortedData, handleSort, sorting } = useSorting('forecasting', tableData, { key: 'plantation', direction: 'ascending' });

  const handleSort = (key:string) => {
    const sortsUpdate = {
      key: key,
      direction: sorting.key == key && sorting.direction == 'ascending' ? 'descending' : 'ascending'
    }
    setSorting(sortsUpdate)
  }


  const renderTableData = () => {
            
    if(sorting.key) 
    tableData.sort((a, b) => {
      if (a[sorting.key] < b[sorting.key]) return sorting.direction === 'ascending' ? -1 : 1;
      if (a[sorting.key] > b[sorting.key]) return sorting.direction === 'ascending' ? 1 : -1;
      return 0;
    });

    const rows = [];
    if (tableData && tableData.length && tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.plantation || " "}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{item.xploriefif ? 'FIF' : item.xplorievoucher ? 'Voucher' : ''}</BOHTD>
            <BOHTD width={InitialWidths[2]}>{item.property_type == 0 ? "House" : item.property_type == 1 ? "Condo" : ''}</BOHTD>
            <BOHTD width={InitialWidths[3]}>{item.number || " "}</BOHTD>
            <BOHTD width={InitialWidths[4]}>{item.street || " "}</BOHTD>
            <BOHTD width={InitialWidths[5]}>{item.property_name || " "}</BOHTD>
            <BOHTD width={InitialWidths[6]}>{item.potential || " "}</BOHTD>
            <BOHTD width={InitialWidths[7]} textAlign={'right'}>{item.guests}</BOHTD>
            {item.queryResult.map((subItem, index) => {
              const isRented = subItem?.isRented || false;
              const percentage = subItem?.percentage ?? null;
              const bgcolor = getCellColor(percentage, isRented);
              const txtColor = getCellTextColor(percentage, subItem?.price, isRented);
              const content = formatCellContent(subItem);

              return (
                <BOHTDPressable
                  key={index}
                  width={60}
                  style={{
                    backgroundColor: bgcolor,
                    borderRightWidth: 1,
                    borderRightColor: '#f2f2f2',
                    borderLeftWidth: 1,
                    borderLeftColor: '#f2f2f2',
                    paddingHorizontal: 0,
                    height: '100%',
                  }}
                  textProps={{
                    style: {
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: txtColor,
                    }
                  }}
                  onPress={() => {
                    if (subItem) {
                      openReservations({
                        date: subItem.date,
                        address: `${item.number} ${item.street} ${item.property_name} ${item.plantation}`,
                        ids: subItem.ids,
                        potential: subItem?.price ?? 0,
                      });
                    }
                  }}
                >
                  {content}
                </BOHTDPressable>
              );
            })}
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  const headers = [
    { key: 'plantation', label: 'Plantation/Area' },
    { key: 'xploriefif', label: 'FIF or Voucher', formatter: (item) => (item.xploriefif ? 'FIF' : item.xplorievoucher ? 'Voucher' : '') },
    { key: 'property_type', label: 'House or Condo', formatter: (item) => (item.property_type === 0 ? 'House' : item.property_type === 1 ? 'Condo' : '') },
    { key: 'number', label: 'Number' },
    { key: 'street', label: 'Street' },
    { key: 'property_name', label: 'Property Name' },
    { key: 'potential', label: 'Weekly Potential' },
    { key: 'guests', label: 'Guests #' },
  ];
  const tableElement = useMemo(() => (
    <BOHTable>
      <BOHTHead>
        <BOHTR>
          {headers.map(({ key, label }, wIndex) => (
            <BOHTH2
              key={key}
              width={InitialWidths[wIndex++]}
              isSorted={sorting.key === key}
              direction={sorting.key === key ? sorting.direction : null}
              onClick={() => handleSort(key)}
            >
              {label}
            </BOHTH2>
          ))}
          {daysArray.length > 0 && daysArray.map((item, index) => (
            <BOHTH2
              key={index}
              width={60}
              BoxStyle={{ height: 100 }}
              style={{
                whiteSpace: 'nowrap',
                padding: 0,
                marginTop: 10,
                transform: [{ rotate: '-90deg' }]
              }}
            >{item.formattedDate}</BOHTH2>
          ))}
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData()}
      </BOHTBody>
    </BOHTable>
  ), [tableData, sorting]);

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        navigation.navigate('Marketing');
      }}
      screenName={'Marketing Order Potential Forecast'}
      isLoading={isLoading}
    >
      <CommonContainer>
        <BOHToolbar style={{ zIndex: 10 }}>
          <Text style={{ marginRight: 8, fontSize: TextdefaultSize }}>Start</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.start_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('start_date', formattedDate);
            })}
          <Text style={{ marginHorizontal: 8, fontSize: TextdefaultSize }}>End</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.end_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('end_date', formattedDate);
            })}
          <BOHTlbCheckbox
            label={'FIF'}
            style={{ marginLeft: 30, marginRight: 10 }}
            CheckboxProps={{
              value: searchOptions.xploriefif
            }}
            onPress={() => {
              changeSearchOptions('xploriefif', !searchOptions.xploriefif);
            }}
          />
          <BOHTlbCheckbox
            label={'VOUCHER'}
            CheckboxProps={{
              value: searchOptions.xplorievoucher
            }}
            onPress={() => {
              changeSearchOptions('xplorievoucher', !searchOptions.xplorievoucher);
            }}
          />
          <BOHTlbrSearchPicker
            width={136}
            label="Plantation/Area"
            items={[
              { label: '', value: '' },
              ...plantataions.map((item, index) => {
                if (item && item.trim()) {
                  return { label: item.trim(), value: item.trim() };
                } else {
                  return null;
                }
              })
                .filter(item => item !== null)
            ]}
            selectedValue={searchOptions.plantation || ''}
            onValueChange={val => changeSearchOptions('plantation', val)}
          />
          <BOHTlbrSearchPicker
            width={136}
            label="Property Name"
            items={[
              { label: '', value: '' },
              ...propertyNames.map((item, index) => {
                if (item && item.trim()) {
                  return { label: item.trim(), value: item.trim() };
                } else {
                  return null;
                }
              })
                .filter(item => item !== null)
            ]}
            selectedValue={searchOptions.property_name || ''}
            onValueChange={val => changeSearchOptions('property_name', val)}
          />
          <BOHTlbrSearchPicker
            width={136}
            label="PMC"
            items={[
              { label: '', value: '' },
              ...pmcs.map((item, index) => {
                if (item && item.pmc.trim()) {
                  return { label: item.pmc.trim(), value: item.pmc.trim() };
                } else {
                  return null;
                }
              })
                .filter(item => item !== null)
            ]}
            selectedValue={searchOptions.pmc || ''}
            onValueChange={val => changeSearchOptions('pmc', val)}
          />
          <BOHButton
            style={{ marginLeft: 30 }}
            label='Export'
            onPress={exportForecasting}
          />
        </BOHToolbar>
        {tableElement}
      </CommonContainer>
    </BasicLayout>
  );
};

export default OrderPotential;
