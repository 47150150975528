import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SortingState {
    [pageId: string]: {
        key: string;
        direction: 'ascending' | 'descending' | null;
    };
}

const initialState: SortingState = {};

const sortingSlice = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        setSort: (state, action: PayloadAction<{ pageId: string; key: string; direction?: 'ascending' | 'descending' }>) => {
            const { pageId, key, direction } = action.payload;
            if (!state[pageId] || state[pageId].key !== key) {
                state[pageId] = { key, direction: direction || 'ascending' };
            } else {
                state[pageId].direction = state[pageId].direction === 'ascending' ? 'descending' : 'ascending';
            }
        },
    },
});

export const { setSort } = sortingSlice.actions;

export const selectSorting = (state: RootState, pageId: string) =>
    state.sorting[pageId] || { key: '', direction: null };

export const selectSortedData = createSelector(
    [selectSorting, (state: RootState, pageId: string, data: any[]) => data],
    (sorting, data) => {
        if (!sorting.key || !data) return data;

        return [...data].sort((a, b) => {
            if (a[sorting.key] < b[sorting.key]) return sorting.direction === 'ascending' ? -1 : 1;
            if (a[sorting.key] > b[sorting.key]) return sorting.direction === 'ascending' ? 1 : -1;
            return 0;
        });
    }
);

export default sortingSlice.reducer;