import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { Text, StyleSheet, TextProps } from 'react-native';

interface BOHTHProps extends TextProps {
  children?: ReactNode;
  width?: number;
  lastCell?: boolean;
  onClick?: () => void;
  direction?: 'ascending' | 'descending' | null;
  isSorted?: boolean;
}

const BOHTH: React.FC<BOHTHProps> = ({ children, width, lastCell, style, isSorted, onClick, direction, ...rest }) => {
  return (
    <TouchableOpacity onPress={onClick}>
      <View style={[
        styles.defaultTheme,
        width && { width: width },
        lastCell && { borderRightWidth: 0 },
        style
      ]}>
        <Text {...rest} >
          {children}
        </Text>
        {isSorted && (
          <Text>
            {direction === 'ascending' ? '↑' : '↓'}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  defaultTheme: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    whiteSapce: 'noWrap',
    flexDirection: "row",
    gap: 4
  },
});

export default BOHTH;