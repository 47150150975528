import React, { useEffect, useState } from 'react';
import { Text, TouchableHighlight, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { getExtrasData, deleteExtra, getPmcList, deletePmc } from '../../../api/Settings';
import { msgStr } from '../../../common/constants/Message';
import { API_URL } from '../../../common/constants/AppConstants';
import { TextMediumSize } from '../../../common/constants/Fonts';
import { useAlertModal, useConfirmModal } from '../../../common/hooks';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHButton, BOHToolbar } from '../../../common/components/bohtoolbar';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTDImageBox, BOHTH, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import PMCModal from './PMCModal';

const Extras = ({ navigation, openInventory }) => {

  const { showAlert } = useAlertModal();
  const { showConfirm } = useConfirmModal();

  const [tableData, setTableData] = useState([]);
  const [updatePMCTrigger, setUpdatePMCTrigger] = useState(true);

  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [selectedPMC, setSelectedPMC] = useState(null);

  const InitialWidths = [70, 200, 120, 70, 50, 50];

  const openAddPMCModal = () => {
    setAddModalVisible(true);
    setSelectedPMC(null);
  };
  const closeAddPMCModal = () => {
    setAddModalVisible(false);
    setSelectedPMC(null);
  };
  const editExtra = (item) => {
    setSelectedPMC(item);
    setAddModalVisible(true);
  };

  useEffect(() => {
    if (updatePMCTrigger == true) getTable();
  }, [updatePMCTrigger]);

  const removePMC = (id) => {
    showConfirm(msgStr('deleteConfirmStr'), () => {
      deletePmc(id, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setUpdatePMCTrigger(true);
            showAlert('success', jsonRes.message);
            break;
          default:
            if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    });
  };

  const getTable = () => {
    getPmcList((jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdatePMCTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const renderTableData = () => {
    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        let price = '';
        switch (item.option) {
          case 0:
            price = 'Free';
            break;
          case 1:
            price = item.fixed_price;
            break;
          case 2:
            break;
        }
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.id}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{item.pmc}</BOHTD>
            <BOHTD width={InitialWidths[2]} textAlign={'right'}>{item.deal}</BOHTD>
            <BOHTDIconBox width={InitialWidths[4]}>
              <TouchableOpacity
                onPress={() => {
                  editExtra(item);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="edit" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
            <BOHTDIconBox width={InitialWidths[5]}>
              <TouchableOpacity
                onPress={() => {
                  removePMC(item.id);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="times" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        openInventory(null);
      }}
      screenName={'Extras'}
    >
      <CommonContainer>
        <BOHToolbar>
          <BOHButton
            label={'Add'}
            onPress={openAddPMCModal} />
        </BOHToolbar>
        <BOHTable>
          <BOHTHead>
            <BOHTR>
              <BOHTH width={InitialWidths[0]}>{'ID'}</BOHTH>
              <BOHTH width={InitialWidths[1]}>{'PMC'}</BOHTH>
              <BOHTH width={InitialWidths[2]}>{'DEAL'}</BOHTH>
              <BOHTH width={InitialWidths[4]}>{'Edit'}</BOHTH>
              <BOHTH width={InitialWidths[5]}>{'DEL'}</BOHTH>
            </BOHTR>
          </BOHTHead>
          <BOHTBody>
            {renderTableData()}
          </BOHTBody>
        </BOHTable>
      </CommonContainer>

      <PMCModal
        isModalVisible={isAddModalVisible}
        PMC={selectedPMC}
        setUpdateExtraTrigger={setUpdatePMCTrigger}
        closeModal={closeAddPMCModal}
      />
    </BasicLayout>
  );
};

export default Extras;
