import { getAPICall, postAPICall } from './BaseAPI';

export const getDailyLogData = async (payload, cb = (jR, s, e)=>{}) => {
  return await postAPICall('timeclock/getdailylogdata/', payload, cb);
}

export const getLogDetailsData = async (payload, cb = (jR, s, e)=>{}) => {
  return await postAPICall('timeclock/getlogdetailsdata/', payload, cb);
}

export const getCurrentStatus = async (cb=(jR, s, e)=>{}) => {
  return await getAPICall('timeclock/getcurrentstatus/', cb);
}