import { StyleSheet } from 'react-native';
import { TextSmallSize } from '../../../common/constants/Fonts';

export const actionOrderStyle = StyleSheet.create({
  label: {
    color: '#555',
    fontSize: TextSmallSize,
  },
  select: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginVertical: 4,
    padding: 8,
  },
  topContainer: {alignItems:'center'},
  container: {
    marginVertical: 30,
    paddingVertical: 40,
    paddingHorizontal: 60,
    backgroundColor: 'white',
    borderRadius: 8,
    borderWidth: 1,
    borderColor:'#b3b3b3',
    shadowColor:'#999',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 8,
  },
  nextStageButton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    paddingHorizontal: 14,
    borderRadius: 3,
    borderWidth: 0,
    borderColor: '#6c757d',
    backgroundColor: '#0EA4AF',
  },
  stageText: {
    marginRight: 30, 
    height: 43,
    width: 165,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'#262E32', 
    color:'white', 
    borderRadius: 50,
    border: 'double',
    paddingBottom: 2,
    // shadowColor: '#ccc', 
    // shadowOffset: {
    //   width: 8, 
    //   height: 3,},
    // shadowOpacity: 1, 
    // shadowRadius: 0,
  },
  circle: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: '#f2f2f2',
  },
  buttonText: {
    fontSize: 15,
    // letterSpacing: 1,
    fontFamily: 'monospace',
    marginLeft: 10,
    color: 'white',
  },
  outLineButton: {
    alignItems: 'center',
    justifyContent:'center',
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#6c757d',
    marginLeft: 13,
    // fontFamily: 'san'
  },
  outlineBtnText: {
    fontSize: 14,
    letterSpacing: 1,
    textAlign: 'center',
    // color:'#6c757d',
    // marginLeft: 10,
  },
  orderRow: {
    flexDirection: 'row',
    flexWrap:'wrap',
    marginBottom: 2,
    // paddingVertical: 8,
  },
  addItemButton: {
    paddingVertical: 8,
    paddingHorizontal: 14,
    alignItems: 'center',
    borderRadius: 3,
    borderWidth: 0,
    borderColor: '#6c757d',
    backgroundColor: '#2e96e1',
  },
});
