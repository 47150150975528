import React, { useEffect, useMemo, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  Platform
} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getLogDetailsData } from '../../../api/Timeclock';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHButton, BOHTlbrSearchPicker, BOHToolbar, renderBOHTlbDatePicker } from '../../../common/components/bohtoolbar';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTH, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { msgStr } from '../../../common/constants/Message';
import { TextMediumSize, TextdefaultSize } from '../../../common/constants/Fonts';
import { useAlertModal } from '../../../common/hooks';
import { formatDate2, formatDateTime, getDurationFormat } from '../../../common/utils/DateUtils';
import { getDrivers } from '../../../api/User';
import { API_URL } from '../../../common/constants/AppConstants';

const LogDetail = ({ navigation }) => {

  const { showAlert } = useAlertModal();

  const [tableData, setTableData] = useState([]);
  const [updateLogDetailListTrigger, setUpdateLogDetailListTrigger] = useState(false);
  const initialWidths = [200, 160, 120, 800];

  const [users, setUsers] = useState([]);
  const [ searchOptions, setSearchOptions ] = useState({
    user_id:null,
    date:null,
  });

  useEffect(()=>{
    getDrivers((jsonRes) => {
      setUsers(jsonRes);
    });
    loadSearchOption();
  }, [])

  useEffect(()=>{
    setUpdateLogDetailListTrigger(true);
  }, [searchOptions])
  
  const loadSearchOption = async () => {
    const [searchOptions] = await Promise.all([
      AsyncStorage.getItem('__logdetail_options'),
    ]);
    if (searchOptions) {
      setSearchOptions(JSON.parse(searchOptions));
      setUpdateLogDetailListTrigger(true);
    }
  }

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    if (updateLogDetailListTrigger == true) getTable();
  }, [updateLogDetailListTrigger]);

  const getTable = () => {
    if(!searchOptions.user_id || !searchOptions.date ) return;
    getLogDetailsData({searchOptions}, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateLogDetailListTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const getStatusFormat = (status) => {
    switch (status) {
      case 0:
        return "Clocked Out";
      case 1:
        return "Clocked In";
      case 2:
        return "Break";
      case 3:
        return "Lunch";
      default:
        return "Unknown Status";
    }
  }

  
  const exportForecasting = () => {
    location.href = API_URL + `/timeclock/exportdetail?date=${searchOptions.date}&user_id=${searchOptions.user_id}`
  }

  const renderTableData = () => {
    const convertStageToString = (stage) => {
      switch (stage) {
        case null: case 'null': return 'Draft';
        case 0: case '0': return 'Draft';
        case 1: case '1': return 'Provisional';
        case 2: case '2': return 'Confirmed';
        case 3: case '3': return 'Checked out';
        case 4: case '4': return 'Checked in';
        default:  return 'Invalid stage';
      }
    }

    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={initialWidths[0]}>{formatDateTime(new Date(item.createdAt))}</BOHTD>
            <BOHTD width={initialWidths[1]} textAlign='right'>{item.status>0?getDurationFormat(item.duration):''}</BOHTD>
            <BOHTD width={initialWidths[2]}>{getStatusFormat(item.status)}</BOHTD>
            <BOHTD width={initialWidths[3]}>{item.note}</BOHTD>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  const tableElement = useMemo(()=>(
    <BOHTable>
      <BOHTHead>
        <BOHTR>
          <BOHTH width={initialWidths[0]}>{'Time'}</BOHTH>
          <BOHTH width={initialWidths[1]}>{'Duration'}</BOHTH>
          <BOHTH width={initialWidths[2]}>{'Status'}</BOHTH>
          <BOHTH width={initialWidths[3]}>{'Note'}</BOHTH>
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData()}
      </BOHTBody>
    </BOHTable>), [tableData])


    return (
      <BasicLayout
        navigation={navigation}
        screenName={'LogDetail'}
      >
        <CommonContainer>
          <BOHToolbar style={{zIndex:100}}>
            <BOHTlbrSearchPicker
              width={125}
              boxStyle={{margin:0, marginRight:15}}
              label="Driver"
              items={[
                ...users
                  .map((item, index) => {
                    return {label: item.name, value: item.id};
                  })
                  .filter(item => item !== null)
              ]}
              selectedValue={searchOptions.user_id || ''}
              onValueChange={val=>changeSearchOptions('user_id', val)}/>
                        <Text style={{marginRight:8, fontSize:TextdefaultSize}}>Date</Text>
            {Platform.OS == 'web' && 
              renderBOHTlbDatePicker(searchOptions.date, (date) => {
                const year = date.getFullYear();
                const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                changeSearchOptions('date', formattedDate);
            })}
            <BOHButton
              style={{ marginLeft: 30 }}
              label='Export'
              onPress={exportForecasting}
            />
          </BOHToolbar>
          {tableElement}
        </CommonContainer>
      </BasicLayout>
    );
};

export default LogDetail;
