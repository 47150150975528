import React from 'react';
import { Platform } from 'react-native';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Provider as PaperProvider } from 'react-native-paper';

import { AlertModalProvider } from './alertmodal/Provider';
import { ConfirmModalProvider } from './confirmmodal/Provider';
import { BasicModalProvider } from './basicmodal/Provider';
import { ScreenSizesProvider } from './screensizes/Provider';
import { AutocompleteDropdownContextProvider } from 'react-native-autocomplete-dropdown';
import { HambugerMenuHisotryProvider } from './hambugermenuhistory/Provider';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GOOGLE_MAP_KEY } from '../../../env';

// const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

// const StripeProviderBaseoffPlatform = ({ children }) => {
//   if (Platform.OS === 'web') {
//     return <Elements stripe={stripePromise}>{children}</Elements>;
//   } else {
//     return children;
//   }
// }

export const Providers = ({ children }) => (
  <AutocompleteDropdownContextProvider>
    {/* <StripeProviderBaseoffPlatform> */}
      <ScreenSizesProvider>
        <PaperProvider>
          <AlertModalProvider>
            <ConfirmModalProvider>
              <BasicModalProvider>
                <HambugerMenuHisotryProvider>
                  <APIProvider apiKey={GOOGLE_MAP_KEY}>
                    {children}
                  </APIProvider>
                </HambugerMenuHisotryProvider>
              </BasicModalProvider>
            </ConfirmModalProvider>
          </AlertModalProvider>
        </PaperProvider>
      </ScreenSizesProvider>
    {/* </StripeProviderBaseoffPlatform> */}
  </AutocompleteDropdownContextProvider>
);
