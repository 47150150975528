import { getAPICall, postAPICall } from './BaseAPI';

export const getPreMessagesData = async (cb = (jR, s, e)=>{}) => {
  return await getAPICall('marketing/getpremessagesdata/',  cb);
}

export const createPreMessage = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('marketing/createpremessage', payload, cb);
};

export const updatePreMessage = async (payload, cb=(jR, s, e)=>{}) => {
  return await postAPICall('marketing/updatepremessage', payload, cb);
};

export const deletePreMessage = (id, cb=(jR, s, e)=>{}) => {
  const payload = { id };
  postAPICall('marketing/deletepremessage', payload, cb);
}