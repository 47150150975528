import React, { useState, useEffect } from 'react';
import {
    Text,
    View,
    ActivityIndicator,
} from 'react-native';
import LabeledTextInput from '../../../common/components/bohform/LabeledTextInput';
import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter, commonModalStyle } from '../../../common/components/basicmodal';
import { createPmc, updatePmc } from '../../../api/Settings';
import { useAlertModal } from '../../../common/hooks';
import { BOHButton } from '../../../common/components/bohtoolbar';
import { RadioButton } from 'react-native-paper';

interface FormValues {
    pmc: string;
    deal: string; // Keep deal as a string, allowing for an empty string
}

const PMCModal = ({
    isModalVisible,
    PMC,
    setUpdateExtraTrigger,
    closeModal,
}) => {
    const isUpdate = Boolean(PMC);
    const { showAlert } = useAlertModal();
    const [isLoading, setIsLoading] = useState(false);
    const [validMessage, setValidMessage] = useState('');
    const [formValues, setFormValues] = useState<FormValues>({
        pmc: '',
        deal: '', // Initialize as an empty string
    });

    useEffect(() => {
        if (isModalVisible) {
            setFormValues({
                pmc: PMC?.pmc || '',
                deal: PMC?.deal || '',
            });
        }
    }, [isModalVisible, PMC]);

    const changeFormValue = (name: keyof FormValues, value: string) => {
        // Set deal to an empty string if "NONE" is selected
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateInput = () => {
        if (!formValues.pmc.trim() || (formValues.deal === undefined && formValues.deal.trim() === '')) {
            setValidMessage('All fields are required.');
            return false;
        }
        setValidMessage('');
        return true;
    };

    const handleResponse = (jsonRes: any, status: number) => {
        setIsLoading(false);
        if (status === 201) {
            showAlert('success', jsonRes.message);
            setUpdateExtraTrigger(true);
            closeModal();
        } else if (status === 409) {
            setValidMessage(jsonRes.error);
        } else {
            showAlert('error', jsonRes.error || 'An unknown error occurred.');
            closeModal();
        }
    };

    const submitHandler = async () => {
        if (!validateInput()) return;

        setIsLoading(true);
        const dataToSend = { pmc: formValues.pmc, deal: formValues.deal === "NONE" ? null : formValues.deal };

        const apiCall = isUpdate ? updatePmc : createPmc;
        apiCall({ ...dataToSend, id: isUpdate ? PMC.id : undefined }, handleResponse);
    };

    return isModalVisible ? (
        <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <BasicModalContainer>
                <ModalHeader
                    label={isUpdate ? 'Update PMC' : 'Add PMC'}
                    closeModal={closeModal}
                />
                <ModalBody>
                    <LabeledTextInput
                        label='PMC'
                        placeholder="PMC"
                        value={formValues.pmc}
                        onChangeText={val => changeFormValue('pmc', val)}
                        placeholderTextColor="#ccc"
                    />
                    <View style={{ flexDirection: 'row', marginVertical: 10 }}>
                        {['FIF', 'VOUCHER', 'NONE'].map(option => (
                            <View key={option} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
                                <RadioButton
                                    value={option}
                                    status={formValues.deal === option || (!formValues.deal && option === 'NONE') ? 'checked' : 'unchecked'}
                                    color='#0099ff'
                                    onPress={() => changeFormValue('deal', option)}
                                />
                                <Text>{option}</Text>
                            </View>
                        ))}

                    </View>

                    {validMessage && (
                        <Text style={{ color: 'red', marginTop: 10 }}>{validMessage}</Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <BOHButton
                        label={isUpdate ? 'Update PMC' : 'Add PMC'}
                        onPress={submitHandler}
                        disabled={isLoading}
                    >
                        {isLoading ? <ActivityIndicator size="small" color="#fff" /> : (isUpdate ? 'Update PMC' : 'Add PMC')}
                    </BOHButton>
                </ModalFooter>
            </BasicModalContainer>
            {isLoading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
        </View>
    ) : null;
};

const styles = commonModalStyle;

export default PMCModal;
