import { Platform } from 'react-native';
import { BOHDISYS_API_URL, BOHDISYS_BASE_URL } from '../../../env';

export const API_URL = BOHDISYS_API_URL;
export const BASE_URL = BOHDISYS_BASE_URL;
export const MapPosition = {lat: 32.19, lng: -80.74};

export const CONFIG = {
  PLATFORM: Platform.OS,
};
