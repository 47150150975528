import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { deletePreMessage, getPreMessagesData } from '../../../api/Marketing';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHTBody, BOHTD, BOHTDIconBox, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { BOHButton, BOHToolbar } from '../../../common/components/bohtoolbar';
import { msgStr } from '../../../common/constants/Message';
import { TextMediumSize } from '../../../common/constants/Fonts';
import { useAlertModal, useConfirmModal } from '../../../common/hooks';

import AddPreMessageModal from './AddPreMessageModal';
import { getBrandsData } from '../../../api/Price';

const PreMessages = ({ navigation }) => {
  const { showAlert } = useAlertModal();
  const { showConfirm } = useConfirmModal();
  
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updatePreMessageTrigger, setUpdatePreMessagesTrigger] = useState(true);
  const InitialWidths = [110, 110, 200, 700, 50, 50];

  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [selectedPreMessage, setSelectedPreMessage] = useState(null);
  const [brands, setBrands] = useState([]);

  const openAddPreMessageModal = () => {
    setAddModalVisible(true);
    setSelectedPreMessage(null);
  };
  const closeAddPreMessageModal = () => {
    setAddModalVisible(false);
    setSelectedPreMessage(null);
  };
  const editPreMessage = (item) => {
    setSelectedPreMessage(item);
    setAddModalVisible(true);
  };

  useEffect(() => {
    getBrandsData((jsonRes) => {
      setBrands(
        jsonRes.map(item => ({
          id: item.id,
          name: item.brand  
        }))
      );
    });
  }, []);
  
  useEffect(() => {
    if (updatePreMessageTrigger == true) getTable();
  }, [updatePreMessageTrigger]);

  const removePreMessage = (id) => {
    showConfirm(msgStr('deleteConfirmStr'), () => {
      deletePreMessage(id, (jsonRes, status, error) => {
        switch (status) {
          case 200:
            setUpdatePreMessagesTrigger(true);
            showAlert('success', jsonRes.message);
            break;
          default:
            if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
            else showAlert('error', msgStr('unknownError'));
            break;
        }
      });
    });
  };

  const getTable = () => {
    setIsLoading(true);
    getPreMessagesData((jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdatePreMessagesTrigger(false);
          setTableData(jsonRes);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
    });
  };

  const renderTableData = useMemo(() => {
    const rows = [];
    if (tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]} textAlign='right'>{item.before}</BOHTD>
            <BOHTD width={InitialWidths[1]} textAlign='right'>{item.after}</BOHTD>
            <BOHTD width={InitialWidths[2]}>
              {brands
                .filter((brand: any) => item.brand_ids.includes(brand.id))
                .map((brand: any) => brand.name)
                .join('\n')}
            </BOHTD>
            <BOHTD width={InitialWidths[3]}>{item.template}</BOHTD>
            <BOHTDIconBox width={InitialWidths[4]}>
              <TouchableOpacity
                onPress={() => {
                  editPreMessage(item);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="edit" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
            <BOHTDIconBox width={InitialWidths[5]}>
              <TouchableOpacity
                onPress={() => {
                  removePreMessage(item.id);
                }}
              >
                <FontAwesome5 size={TextMediumSize} name="times" color="black" />
              </TouchableOpacity>
            </BOHTDIconBox>
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    
    setIsLoading(false);
    return <>{rows}</>;
  }, [tableData]);

  const tableElement = useMemo(()=>(        
    <BOHTable isLoading={isLoading}>
      <BOHTHead>
        <BOHTR>
          <BOHTH2 width={InitialWidths[0]}>{'Before(days)'}</BOHTH2>
          <BOHTH2 width={InitialWidths[1]}>{'After(days)'}</BOHTH2>
          <BOHTH2 width={InitialWidths[2]}>{'Brands'}</BOHTH2>
          <BOHTH2 width={InitialWidths[3]}>{'template'}</BOHTH2>
          <BOHTH2 width={InitialWidths[4]}>{'Edit'}</BOHTH2>
          <BOHTH2 width={InitialWidths[5]}>{'DEL'}</BOHTH2>
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData}
      </BOHTBody>
    </BOHTable>), [isLoading, tableData])

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        navigation.navigate('Marketing');
      }}
      screenName={'PreMessage Manager'}
    >
      <CommonContainer>
        <BOHToolbar>
          <BOHButton
            label="Add"
            onPress={openAddPreMessageModal}/>
        </BOHToolbar>
        {tableElement}
        <AddPreMessageModal
          isModalVisible={isAddModalVisible}
          details={selectedPreMessage}
          setUpdatePreMessagesTrigger={setUpdatePreMessagesTrigger}
          closeModal={closeAddPreMessageModal}
        />
      </CommonContainer>
    </BasicLayout>
  );
};

export default PreMessages;
