import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSortedData, setSort, selectSorting } from '../../redux/slices/sortingSlice';
import { RootState } from '../../redux/store';

export const useSorting = (pageId: string, data: any[], initialSort: { key: string, direction: 'ascending' | 'descending' }) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setSort({ pageId, ...initialSort }));
    }, [dispatch, pageId, initialSort.key, initialSort.direction]);

    const sorting = useSelector((state: RootState) => selectSorting(state, pageId));
    const sortedData = useSelector((state: RootState) => selectSortedData(state, pageId, data));

    const handleSort = (key: string) => {
        dispatch(setSort({
            pageId,
            key,
            direction: sorting.key === key && sorting.direction === 'ascending' ? 'descending' : 'ascending'
        }));
    };

    return { sortedData, handleSort, sorting };
};