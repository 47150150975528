import React, { useEffect, useMemo, useState } from 'react';
import { Text, Platform, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getDailyLogData } from '../../../api/Timeclock';
import { BasicLayout, CommonContainer } from '../../../common/components/CustomLayout';
import { BOHTBody, BOHTD, BOHTDPressable, BOHTH2, BOHTHead, BOHTR, BOHTable } from '../../../common/components/bohtable';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';
import { BOHToolbar, renderBOHTlbDatePicker } from '../../../common/components/bohtoolbar';
import { TextdefaultSize } from '../../../common/constants/Fonts';
import { formatDate } from '../../../common/utils/DateUtils';

const DailyLog = ({ navigation }) => {
  const { showAlert } = useAlertModal();

  const [tableData, setTableData] = useState([]);
  const [updateLocationTrigger, setUpdateLocationsTrigger] = useState(false);
  const InitialWidths = [100, 120, 100];
  const [searchKey, setSearchKey] = useState('');
  const [daysArray, setdaysArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    key: null,
    direction: null,
  })

  const today = new Date();
  const sevenDaysBeforeToday = new Date(today);
  sevenDaysBeforeToday.setDate(today.getDate() - 7);

  const [searchOptions, setSearchOptions] = useState({
    start_date: formatDate(sevenDaysBeforeToday),
    end_date: formatDate(today),
  });

  const changeSearchOptions = (key, val) => {
    setSearchOptions(prevOptions => ({
      ...prevOptions,
      [key]: val
    }));
  }

  useEffect(() => {
    setLoading(true);
    setUpdateLocationsTrigger(true);
  }, [searchOptions])

  useEffect(() => {
    if (updateLocationTrigger == true) getTable();
  }, [updateLocationTrigger]);

  useEffect(() => {
    setUpdateLocationsTrigger(true);
  }, [searchKey])

  useEffect(() => {
    const interval = setInterval(() => {
      getTable();
    }, 20 * 1000);

    return () => clearInterval(interval);
  }, []);

  const getTable = () => {
    setLoading(true);
    getDailyLogData({ searchOptions }, (jsonRes, status, error) => {
      switch (status) {
        case 200:
          setUpdateLocationsTrigger(false);
          setTableData(jsonRes.gridData);
          // setTotalNights(jsonRes.totalNights);
          setdaysArray(jsonRes.daysArray);
          break;
        case 500:
          showAlert('error', msgStr('serverError'));
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          break;
      }
      setLoading(false);
    });
  };
  
  const openDetail = (searchOptions) => {
    console.log(searchOptions);
    AsyncStorage.setItem('__logdetail_options', JSON.stringify(searchOptions))

    if (Platform.OS == 'web') {
      window.open('/home/logdetail');
    }
  }

  const handleSort = (key:string) => {
    const sortsUpdate = {
      key: key,
      direction: sorting.key == key && sorting.direction == 'ascending' ? 'descending' : 'ascending'
    }
    setSorting(sortsUpdate)
  }

  const getStatusFormat = (status) => {
    let color;
    let statusText;
  
    switch (status) {
      case 0:
        color = 'red';
        statusText = "Clocked Out";
        break;
      case 1:
        color = 'green';
        statusText = "Clocked In";
        break;
      case 2:
        color = 'orange';
        break;
      case 3:
        color = 'blue';
        statusText = "Lunch";
        break;
      default:
        color = 'gray';
        statusText = "Unknown Status";
    }
  
    return (
      <View style={{flexDirection: 'row', alignItems: 'center',}}>
        <View style={[{ width: 10, height: 10, borderRadius: 5, marginRight: 8, backgroundColor: color }]} />
        <Text>{statusText}</Text>
      </View>
    );
  };
  
  const renderTableData = () => {
            
    if(sorting.key) 
    tableData.sort((a, b) => {
      if (a[sorting.key] < b[sorting.key]) return sorting.direction === 'ascending' ? -1 : 1;
      if (a[sorting.key] > b[sorting.key]) return sorting.direction === 'ascending' ? 1 : -1;
      return 0;
    });

    const rows = [];
    if (tableData && tableData.length && tableData.length > 0) {
      tableData.map((item, index) => {
        rows.push(
          <BOHTR key={index}>
            <BOHTD width={InitialWidths[0]}>{item.name}</BOHTD>
            <BOHTD width={InitialWidths[1]}>{getStatusFormat(item.currentStatus)}</BOHTD>
            {daysArray.length > 0 && daysArray.map((day, index) => (
              <BOHTDPressable 
                key={index} 
                width={InitialWidths[2]} 
                textAlign='right'
                onPress={()=>{
                  openDetail({
                    user_id: item.user_id,
                    date: day.date
                  })
                }}>{item.logs[day.formattedDate] || ""}</BOHTDPressable>
            ))}
          </BOHTR>
        );
      });
    } else {
      <></>;
    }
    return <>{rows}</>;
  };

  const headers = [
    { key: 'Driver', label: 'Driver' },
    { key: 'CurrentStatus', label: 'Current Status' },
  ];
  const tableElement = useMemo(() => (
    <BOHTable>
      <BOHTHead>
        <BOHTR>
          {headers.map(({ key, label }, wIndex) => (
            <BOHTH2
              key={key}
              width={InitialWidths[wIndex]}
              isSorted={sorting.key === key}
              direction={sorting.key === key ? sorting.direction : null}
              onClick={() => handleSort(key)}
            >
              {label}
            </BOHTH2>
          ))}
          {daysArray.length > 0 && daysArray.map((item, index) => (
            <BOHTH2
              key={index}
              width={InitialWidths[2]}
              style={{
                textAlign: 'center'
              }}
            >{item.formattedDate}</BOHTH2>
          ))}
        </BOHTR>
      </BOHTHead>
      <BOHTBody>
        {renderTableData()}
      </BOHTBody>
    </BOHTable>
  ), [tableData, sorting]);

  return (
    <BasicLayout
      navigation={navigation}
      goBack={() => {
        navigation.navigate('Marketing');
      }}
      screenName={'TimeClock Logs'}
      isLoading={isLoading}
    >
      <CommonContainer>
        <BOHToolbar style={{ zIndex: 10 }}>
          <Text style={{ marginRight: 8, fontSize: TextdefaultSize }}>Start</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.start_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('start_date', formattedDate);
            })}
          <Text style={{ marginHorizontal: 8, fontSize: TextdefaultSize }}>End</Text>
          {Platform.OS == 'web' &&
            renderBOHTlbDatePicker(searchOptions.end_date, (date) => {
              const year = date.getFullYear();
              const formattedDate = `${year}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
              changeSearchOptions('end_date', formattedDate);
            })}
          {/* <BOHButton
            style={{ marginLeft: 30 }}
            label='Export'
            onPress={exportForecasting}
          /> */}
        </BOHToolbar>
        {tableElement}
      </CommonContainer>
    </BasicLayout>
  );
};

export default DailyLog;
